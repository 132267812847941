import { SchemaApiResponse } from '@ilc-technology/forms';

class SchemaProvider {
    getSchema(): SchemaApiResponse {
        let cachedSchema = schemaCacheProvider.getSchema();
        if (!!cachedSchema) {
            return JSON.parse(cachedSchema);
        }

        return defaultSchema;
    }
};

class SchemaCacheProvider {
    private _cacheKey = "schemaResponse";

    getSchema(): string | null {
        return localStorage.getItem(this._cacheKey);
    }

    setSchema(schema: string) {
        localStorage.setItem(this._cacheKey, schema);
    }

    clear() {
        localStorage.removeItem(this._cacheKey);
    }
}

const defaultSchema: SchemaApiResponse = {
    "schema": {
        "title": "Schema title",
        "description": "Schema description",
        "type": "object",
        "properties": {
            "firstName": {
                "type": "string",
                "presentation": {
                    "moduleId": "name_1.0",
                    "label": "First name",
                    "markAsRequired": true,
                    "fieldType": "textInput"
                },
                "pattern": "^[^0-9]+$",
                "maxLength": 50,
                "errorMessage": {
                    "valueRequired": "The field is required",
                    "maxLength": "The value for field must have maximum 50 characters",
                    "pattern": "Numbers not allowed"
                },
                "valueRequired": true
            },
            "lastName": {
                "type": "string",
                "presentation": {
                    "moduleId": "name_1.0",
                    "label": "Last name",
                    "markAsRequired": true,
                    "fieldType": "textInput"
                },
                "pattern": "^[^0-9]+$",
                "maxLength": 50,
                "errorMessage": {
                    "valueRequired": "The field is required",
                    "maxLength": "The value for field must have maximum 50 characters",
                    "pattern": "Numbers not allowed"
                },
                "valueRequired": true
            }
        }
    },
    "options": {},
    "properties": {
        "schemaJwtToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJzY2hlbWFWZXJzaW9uIjoiMiIsInR5cGUiOiJvYmplY3QiLCJwcm9wZXJ0aWVzIjp7ImZpcnN0TmFtZSI6eyJ0eXBlIjoic3RyaW5nIiwicGF0dGVybiI6Il5bXjAtOV0rJCIsIm1heExlbmd0aCI6NTAsInZhbHVlUmVxdWlyZWQiOnRydWV9LCJsYXN0TmFtZSI6eyJ0eXBlIjoic3RyaW5nIiwicGF0dGVybiI6Il5bXjAtOV0rJCIsIm1heExlbmd0aCI6NTAsInZhbHVlUmVxdWlyZWQiOnRydWV9fX0.PjQi5t_uqbkc7R4FEHpRgWxEsqFRbGrIqoxAzhsFSD4"
    }
}

export const schemaProvider = new SchemaProvider();
export const schemaCacheProvider = new SchemaCacheProvider();