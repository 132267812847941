import React, { useState } from 'react'
import Navbar from './layout/Navbar'
import { FormComponent } from './FormComponent'
import { EditorComponent } from './EditorComponent'
import { schemaProvider, schemaCacheProvider } from './data/SchemaProvider'

function App() {
  const [submissionPayload, setSubmissionPayload] = useState<object>({})
  const schema = schemaProvider.getSchema();
  const [editorValue, setEditorValue] = useState(JSON.stringify(schema, null, 2));

  const resetValue = function () {
    schemaCacheProvider.clear();
    window.location.reload();
  };

  const setValue = function () {
    const value = editorValue;

    try {
      let schema = JSON.parse(value || '{}');
      schema.schema = schema.schema || {};
      schema.schema.properties = schema.schema.properties || {};
      schema.properties = schema.properties || {};
      schema.options = schema.options || {};

      schemaCacheProvider.setSchema(JSON.stringify(schema, null, 4));
      window.location.reload();
    } catch (e) {
      alert(e);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="card border-0">
        <div className="card-body">
          <div className="container">
            <div className="mb-5">
              <div className="card">
                <div className="card-header">
                  Form
                </div>
                <div className="card-body">
                  <FormComponent schema={schema} onSubmission={setSubmissionPayload} />
                </div>
              </div>

              {!!Object.keys(submissionPayload).length &&
                <div className="card mt-5">
                  <div className="card-header">
                    Submitted payload
                  </div>
                  <div className="card-body">
                    <pre>{JSON.stringify(submissionPayload, undefined, 2)}</pre>
                  </div>
                </div>
              }
            </div>

            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <div>
                    <code>POST /schemas</code> response
                  </div>
                  <div className="ms-auto">
                    <div role="group">
                      <button className="btn btn-primary btn-sm me-2" onClick={() => setValue()}>Update form</button>
                      <button className="btn btn-danger btn-sm" onClick={() => resetValue()}>Reset to default</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <EditorComponent
                  value={editorValue}
                  onValueChange={setEditorValue}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
