function Navbar() {
    return (
        <nav className="navbar navbar-light bg-light">
            <div className="container">
                <a className="navbar-brand" href="/">
                    FormsBuilder - client app
                </a>
            </div>
        </nav>
    );
}

export default Navbar;