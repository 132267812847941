import React from 'react'
import { Form, FormConfig, SchemaApiResponse } from '@ilc-technology/forms'

const marketCode = 'gb'
const reference = 'storybook'
const config = {
  marketCode,
  formIntent: 'br',
  reference,
  modules: []
}

interface Props {
  schema: SchemaApiResponse
  onSubmission: React.Dispatch<object>
}

export function FormComponent({ schema, onSubmission }: Props) {
  const onSubmissionCallback = (values: unknown) => {
    onSubmission(values as object);
    return new Promise<void>((resolve => {
      resolve();
    }));
  }

  return (
    <Form
      marketCode={marketCode}
      reference={reference}
      isQa={true}
      type="config"
      schema={schema}
      config={config as unknown as FormConfig}
      onSubmission={onSubmissionCallback}
    />
  )
}
