import React, { useRef } from 'react'
import { Editor } from '@monaco-editor/react'

interface Props {
  value: string,
  onValueChange: React.Dispatch<any>
}

export function EditorComponent({ value, onValueChange }: Props) {
  const onChange = (value: string | undefined) => {
    onValueChange(value);
  };

  return (
    <Editor
      onChange={onChange}
      height="90vh"
      defaultLanguage="json"
      theme="light"
      value={value}
    />
  )
}
